// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'


export const getAllTransactions = createAsyncThunk(
    'allTransactions/getAllTransactions',
    async (params) => {
        const response = await axios.get(baseUrl() + '/myt/getAll', { params })
        return {
            totalPages: response.data.totalCount,
            params: response.data.params,
            data: response.data.data
        }
    }
)

export const getUserContactList = createAsyncThunk('user/getUserContactList', async () => {
    const response = await axios.get(baseUrl() + `/enduser/contacts`)

    return {
        data: response.data.data
    }
})

export const getClientContactList = createAsyncThunk('user/getClientContactList', async () => {
    const response = await axios.get(baseUrl() + `/company/getcontacts`)

    return {
        data: response.data.data
    }
})
export const getJuiceTransactions = createAsyncThunk(
    'allTransactions/getJuiceTransactions',
    async (params) => {
        const response = await axios.get(baseUrl() + '/payproof/getfiles', { params })
        return {
            totalPages: response.data.count.total,
            params: response.data.params,
            data: response.data.data
        }
    }
)

export const getBlinkTransactionList = createAsyncThunk(
    'allTransactions/getBlinkTransactionList',
    async (params) => {
        const response = await axios.get(baseUrl() + '/blink/getAll', { params })
        return {
            totalPages: response.data.totalCount,
            params: response.data.params,
            data: response.data.data
        }
    }
)


export const sendNotification = createAsyncThunk(
    'allTransactions/sendNotification',
    async ({ payload, params }, { dispatch, getState }) => {
        console.log("notify", params, payload)
        try {
            await axios.post(baseUrl() + '/fcm/sendAll', payload)
            toast.success('Notification Send to all successfully')
            dispatch(setStatus("0"))
            await dispatch(getAllTransactions({ params: params }))
            return true
        } catch (error) {
            toast.error(error?.response?.data.error)

            return false
        }
    }
)

export const sendUserNotification = createAsyncThunk(
    'allTransactions/sendUserNotification',
    async ({ payload, params }, { dispatch, getState }) => {
        console.log("notify", params, payload)
        try {
            await axios.post(baseUrl() + '/fcm/senduser', payload)
            toast.success('Notification Send to Users successfully')
            dispatch(setStatus("0"))
            await dispatch(getAllTransactions({ ...params }))
            return true
        } catch (error) {
            toast.error(error?.response?.data.error)

            return false
        }
    }
)

export const sendClientNotification = createAsyncThunk(
    'allTransactions/sendClientNotification',
    async ({ payload, params }, { dispatch, getState }) => {
        try {
            await axios.post(baseUrl() + '/fcm/sendclient', payload)
            toast.success('Notification Send to Owners successfully')
            dispatch(setStatus("0"))
            await dispatch(getAllTransactions({ params: params }))
            return true
        } catch (error) {
            toast.error(error?.response?.data.error)

            return false
        }
    }
)

export const sendWhatsAppNotification = createAsyncThunk(
    'allTransactions/sendWhatsAppNotification',
    async ({ payload, params }, { dispatch, getState }) => {
        try {
            await axios.post(baseUrl() + '/fcm/sendmeta', payload)
            toast.success('WhatsApp Notification Send successfully')
            dispatch(setStatus("1"))
            dispatch(getJuiceTransactions({ params: params }))
            return true
        } catch (error) {
            toast.error(error?.response?.data.error)
            return false
        }
    }
)

export const sendTextMessage = createAsyncThunk(
    'allTransactions/sendTextMessage',
    async ({ payload, params }, { dispatch, getState }) => {
        try {
            await axios.post(baseUrl() + '/fcm/sendtext', payload)
            toast.success('Text Message Send successfully')
            dispatch(setStatus("2"))
            dispatch(getBlinkTransactionList({ params: params }))
            return true
        } catch (error) {
            toast.error(error?.response?.data.error)

            return false
        }
    }
)
export const deleteJuiceTransactions = createAsyncThunk(
    'juicePay/deleteJuiceTransactions',
    async ({ id, params }, { dispatch, getState }) => {
        try {
            await axios.delete(baseUrl() + `/payproof/deletefile/${id}`),
                toast.success('Transaction Deleted successfully')
            dispatch(setStatus("2"))
            await dispatch(getJuiceTransactions({ params: params }))
            return true
        } catch (error) {
            toast.error(error?.response?.data?.result)
            return false
        }
    }
)

export const deleteNotification = createAsyncThunk(
    'allTransactions/deleteNotification',
    async ({ id }, { dispatch, getState }) => {
        try {
            await axios.delete(baseUrl() + `/allTransactions/${id}`),
                toast.success('Location Deleted successfully')
            await dispatch(getAllTransactions())
            return true
        } catch (error) {
            toast.error(error?.response?.data?.result)
            return false
        }
    }
)

export const allTransactions = createSlice({
    name: 'allTransactions',
    initialState: {
        transactionsList: [],
        selectAllTransactions: [],
        allNotificationList: [],
        userContactsList: [],
        clientContactList: [],
        reload: false,
        loader: false,
        rowsPerPage: 10,
        currentPage: 1,
        totalPages: 0,
        selected: null,
        status: '0',
        loading: false,
        clearFilter: false,
        selected: null,
        params: {
            pageNo: 1,
            pageSize: 10
        }

    },
    reducers: {
        selectAllTransactions: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        },
        selectCurrentPage: (state, action) => {
            if (action.payload === null) {
                state.currentPage = null
            } else {
                state.currentPage = action.payload
            }
        },
        selectRowsPerPage: (state, action) => {
            if (action.payload === null) {
                state.rowsPerPage = null
            } else {
                state.rowsPerPage = action.payload
            }
        },
        selectClearFilter: (state, action) => {
            state.clearFilter = action.payload
        },
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setLoader: (state) => {
            state.loader = !state.loader
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }

    },

    extraReducers: {
        [sendNotification.pending]: (state) => {
            state.loader = true
        },
        [sendNotification.fulfilled]: (state, action) => {
            state.reload = !state.reload
            state.loader = false
        },
        [sendNotification.rejected]: (state) => {
            state.loader = false
        },
        [getAllTransactions.pending]: (state) => {
            state.loader = true
        },
        [getAllTransactions.fulfilled]: (state, action) => {
            state.transactionsList = action.payload.data
            state.totalPages = action.payload.totalPages
            state.loader = false
        },
        [getAllTransactions.rejected]: (state) => {
            state.loader = false
        },
        [getUserContactList.fulfilled]: (state, action) => {
            state.userContactsList = action.payload.data
        },
        [getClientContactList.fulfilled]: (state, action) => {
            state.clientContactList = action.payload.data
        },
        [getJuiceTransactions.pending]: (state) => {
            state.loader = true
        },
        [getJuiceTransactions.fulfilled]: (state, action) => {
            state.transactionsList = action.payload.data
            state.totalPages = action.payload.totalPages
            state.loader = false
        },
        [getJuiceTransactions.rejected]: (state) => {
            state.loader = false
        },
        [getBlinkTransactionList.fulfilled]: (state, action) => {
            state.transactionsList = action.payload.data
            state.totalPages = action.payload.totalPages
            state.loader = false
        },
    }
})

export const { selectAllTransactions, selectCurrentPage,
    selectRowsPerPage,
    selectClearFilter,
    setStatus,
    setLoader,
    setLoading } = allTransactions.actions

export default allTransactions.reducer
